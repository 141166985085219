import React from 'react'
import { RichText } from 'prismic-reactjs'
import { getColumnClass, getColumnRemainderClass } from '../../../utils/columns'
import { linkResolver } from '../../../utils/linkResolver'
import Link from '../../link'

import './index.scss'

export default ({ primary, item }) => {

    const reversed = item.column_direction === 'Reverse'

    let link = item.link ? 
        <Link 
            to={item.link} 
            anchor={item.link_anchor}
            className="o-btn u-btn-primary">{item.link_text}</Link> 
    : <></>

    let contentColumn = (
        <div className={getColumnClass('1/2') + ' content-column col-dir-' + item.column_direction.toLowerCase()}>
            <div className="column-inner" style={{ '--max-width': item.content_max_width }}>
                <div className="o-header">
                    <div class="o-pre-title"><RichText render={item.pretitle} linkResolver={linkResolver} /></div>
                    <RichText render={item.title} linkResolver={linkResolver} />
                </div>
                <div className="o-content">
                    <RichText render={item.content} linkResolver={linkResolver} />
                </div>
                {link}
            </div>
        </div>
    )

    let secondaryColumn = (
        <div className={getColumnRemainderClass('1/2') + ' image-column'}>
            {item.image ? <img src={item.image.url} alt={item.image.alt} className="img-fluid d-block mx-auto" /> : <></>}
        </div>
    )

    let layout = (
        <>
            {reversed ? secondaryColumn : contentColumn}
            {reversed ? contentColumn : secondaryColumn}
        </>
    )

    return (<div className="content-block"><div className="row align-items-center">{layout}</div></div>)

}