import React from 'react'
import { Link } from 'gatsby'
import { Link as PrismicLink } from 'prismic-reactjs'
import { linkResolver } from '../../../utils/linkResolver'


export default ({ primary }) => {

    return (
        <div class="o-nav">
            {primary.navigation.items.map(item => {
                if (!item.link) return <span>{item.label}</span>
                return <Link to={PrismicLink.url({link_type: 'Document', ...item.link._meta}, linkResolver)} anchor={item.link_anchor}>{item.label}</Link> 
            })}
        </div>
    )
}
