const getBlockStyling = (primary) => {

    let style = {}

    if ( !primary ) return style

    let mapping = [
        {key: 'bg_image', local: 'background-image', value: (primary) => primary.bg_image ? `url(${primary.bg_image.url})` : false},
        {key: 'bg_color', local: '--bg-color'},
        {key: 'bg_color', local: 'background-color'},
        {key: 'text_color', local: 'color'},
        {key: 'text_color', local: '--color'},
        {key: 'padding_top', local: '--pt', value: (primary) => primary.padding_top !== 'Default' ? `${primary.padding_top}px` : false},
        {key: 'padding_bottom', local: '--pb', value: (primary) => primary.padding_bottom !== 'Default' ? `${primary.padding_bottom}px` : false},
    ]

    mapping.map( (item) => {
        let value = (item.value ? item.value(primary) : primary[item.key]) 
        if (value && primary[item.key]) style[item.local] = value
        return item
    })

    return style

}

const getDataAttributes = (primary) => {

    let attrs = {}

    if ( !primary ) return attrs

    let mapping = [
        {key: 'layout', local: 'layout'},
        {key: 'padding_top', local: 'pt'},
        {key: 'padding_bottom', local: 'pb'},
    ]

    mapping.map( (item) => {
        if ( ! primary[item.key] ) return null
        attrs['data-' + item.local] = primary[item.key].toLowerCase()
        return item
    })

    return attrs

}


export { getBlockStyling, getDataAttributes }