import React from 'react'
import { Container } from 'react-bootstrap'
import { getBlockStyling, getDataAttributes } from '../../utils/block'

export default ({ children, primary, type }) => {

    let attrs = getDataAttributes(primary)

    return (
        <section className="o-section" data-slice={type} style={getBlockStyling(primary)} {...attrs}>
            <Container>
                {children}
            </Container>
        </section>
    )

}