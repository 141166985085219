import React from "react"
import Layout from '../components/layout'
import { graphql } from "gatsby"
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import { getColumnClass } from '../utils/columns'
import Section from '../components/Section'
import ContentBlock from '../components/page/ContentBlock'
import Navigation from '../components/blocks/Navigation'



export const query = graphql`
query AboutQuery($uid: String!, $lang: String!) {
    ... HeaderNavFragment
    ... FooterNavFragment
    prismic {
      global(uid: "settings", lang: $lang) {
            about_nav {
              ... NavigationFragment
            }
            about_below_content {
              entity {
                ... on PRISMIC_Content_block {
                    _meta {
                        type
                    }
                    title
                    items {
                        column_direction
                        content
                        image
                        title
                        link_text
                    }
                }
                ... on PRISMIC_Gallery {
                  _meta {
                      type
                  }
                  title
                  photos {
                    image
                    column_width
                  }
                }
                ... on PRISMIC_Hubspot_form {
                  _meta {
                    type
                  }
                  title
                  secondary_title
                  content
                  hubspot_form_id
                }
              }
        }
      }
      about(uid: $uid, lang: $lang) {
            hero_image
            hero_pretitle
            hero_title
            _meta {
              id
              uid
              lang
              type
            }
            body {
                ... on PRISMIC_AboutBodyDouble_column {
                    type
                    primary {
                        left_content
                        right_content
                    }
                }
            }
          }
    }
  }
  
`


const Page = ({ data }) => {

    let doc = data.prismic.about
    let settings = data.prismic.global
    let headerNav = data.header.navigation
    if ( !doc ) return null

    let layoutParams = { doc: doc, headerNav: headerNav, textColor: 'white' }
    let { about_nav, about_below_content } = settings

    let layout = (
        <div className="about-template">
            <section class="o-section u-bg-dark-grey">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-4">
                            {doc.hero_image ? <img src={doc.hero_image.url} alt={doc.hero_image.alt} className="img-fluid" /> : <></>}
                        </div>
                        <div class="col-md-7 offset-md-1">
                            <h1>
                                <p class="o-pre-title">{doc.hero_pretitle}</p>
                                <div class="o-title"><RichText render={doc.hero_title} linkResolver={linkResolver} /></div>
                            </h1>
                            
                        </div>
                    </div>
                </div>
            </section>

            <section class="o-section u-bg-dark-grey">
                <div class="container">
                    <Navigation primary={{ navigation: about_nav }}></Navigation>
                    <div class="body-content">
                        {doc.body.map(slice => {

                            if ( slice.type === 'double_column' ) {
                                return (
                                    <div class="row">
                                        <div class="col-md-6">
                                            <RichText render={slice.primary.left_content} linkResolver={linkResolver} />
                                        </div>
                                        <div class="col-md-6">
                                            <RichText render={slice.primary.right_content} linkResolver={linkResolver} />
                                        </div>
                                    </div>
                                )
                            }

                            return <></>

                        })}
                    </div>
                </div>
            </section>

            {about_below_content.map(({entity}) => {
                if ( entity._meta.type === 'content_block' ) {
                    return (
                        <Section>
                            {entity.items.map(item => {
                                return <ContentBlock item={item}></ContentBlock>
                            })}
                        </Section>
                    )
                } else if ( entity._meta.type === 'gallery' ) {
                    return (
                        <section class="o-section u-bg-dark-grey">
                            <div class="container">
                                <div class="c-photo-gallery">
                                    <div class="row">
                                        {entity.photos.map(item => {
                                            return (
                                                <div class={getColumnClass(item.column_width) + ' gallery-column'}>
                                                    <img src={item.image.url} alt={item.image.alt} className="img-fluid" />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                } else if ( entity._meta.type === 'hubspot_form' ) {
                    return (
                        <section class="o-section u-bg-light-grey">
                            <div class="container">
                                <div class="c-hubspot-form">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <div class="o-secondary-title"><RichText render={entity.secondary_title} linkResolver={linkResolver} /></div>
                                            <div class="o-content">
                                                <RichText render={entity.content} linkResolver={linkResolver} />
                                            </div>
                                        </div>
                                        <div class="col-md-6 offset-md-1">
                                            <RichText render={entity.title} linkResolver={linkResolver} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                }
                return <></>
            })}

        </div>
    )


    return <Layout {...layoutParams}>{layout}</Layout>

}

export default Page